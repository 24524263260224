//项目已经全局引入element的话可以不单独引入
import { Message } from "element-ui";
import VueUeditorWrap from "vue-ueditor-wrap";
import { randomString, returnLetterByIndex, sleep} from "@/utils/function";
import { editorUploadUrl } from "@/utils/const";
import API from "@/api/teacher/questionbank/questionBankUpdate";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "questionBankUpdate",
    inject: ['reload'],
    // 页面组件
    components: {
        VueUeditorWrap
    },
    // 数据
    data () {
        return {
            myConfig: {
                // 控制添加题目框的显示与隐藏
                addshow: true,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                    [
                        "inserttable",//表格
                        "source", //源代码
                        "bold", //加粗
                        "italic", //斜体
                        "underline", //下划线
                        "strikethrough", //删除线
                        "fontborder", //字符边框
                        "blockquote", //引用
                        "selectall", //全选
                        "horizontal", //分隔线
                        "removeformat", //清除格式
                        "unlink", //取消链接
                        "deletecaption", //删除表格标题
                        "inserttitle", //插入标题
                        "cleardoc", //清空文档
                        "insertcode", //代码语言
                        "fontfamily", //字体
                        "fontsize", //字号
                        "insertimage", //多图上传
                        "link", //超链接
                        "emotion", //表情
                        "spechars", //特殊字符
                        "searchreplace", //查询替换
                        "insertvideo", //视频
                        "justifyleft", //居左对齐
                        "justifyright", //居右对齐
                        "justifycenter", //居中对齐
                        "forecolor", //字体颜色
                        "insertorderedlist", //有序列表
                        "insertunorderedlist", //无序列表
                        "imageleft", //左浮动
                        "imageright", //右浮动
                        "attachment", //附件
                        "imagecenter", //居中
                        "lineheight", //行间距
                    ],
                ],
            },
            bank_id: 0,
            questionList: [],
            options: [
                {
                    value: "radio",
                    label: "单选题",
                },
                {
                    value: "checkbox",
                    label: "多选题",
                },
                {
                    value: "judge",
                    label: "判断题",
                },
                // {
                //   value: "comple",
                //   label: "填空题",
                // },
            ],
            value: "",
            input: "",
        };
    },
    // 生命周期---创建
    created () {
        const self = this;
        if (self.$route.query.sign == 'update') 
        {
            self.addshow = false;
        } 
        else 
        {
            self.addshow = true;
        };
        self.bank_id = Number(this.$router.currentRoute.query.bank_id);
        let list = this.$router.currentRoute.query.list ?? "";
        if (list) 
            {
            self.questionList = JSON.parse(this.$router.currentRoute.query.list);
            console.log(self.questionList);
        }
        // 获取数据
        self.getData();
    },
    // 方法
    methods: {
        scroll (index) 
        {
            document
                .getElementsByName("item")
            [index].scrollIntoView({ behavior: "smooth" });
        },
        goBack () 
        {
            // this.$router.back()
            let self = this;
            let searchInfo = self.$route.query.searchInfo ? self.$route.query.searchInfo : " ";
            self.$router.push("/teacher/questionList?bank_id=" + self.$route.query.bank_id + '&searchInfo=' + searchInfo + '&name=' + self.$route.query.name);
            // self.$router.push({
            //     path: "/teacher/questionBank",
            //     query: {
            //         // article_id: self.article_id,
            //         // name:self.categoryName,
            //         hideMenu:0
            //     }
            // }).then((res)=>{
            //     self.reload()
            //     // console.log(res);
            // })            
        },
        updateUeditorSign: function (index) {
            const self = this;
            self.questionList[index].resolution.ueState = true;
        },
        addOption (index) 
        {
            var self = this;
            let num = self.questionList[index].optionList.length;
            let letter = returnLetterByIndex(num);
            let optionObj = {
                // 选项
                index: "", // A
                // 选项内容
                content: "" // 选项内容
            };
            optionObj.index = letter;
            self.questionList[index].optionList.push(optionObj);
            // Message.success("添加成功！");
            this.$Tips({
                // 消息提示内容
                message: '添加成功',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })

        },
        addquestion (sign) {
            const self = this;
            let obj = {
                // ===========================  ueditor  使用代码 start   ================
                defaultMsg: "", //默认显示的文字
                ue1: "", // 不同编辑器必须不同的id
                ueState: false, //ue状态
                // ===========================  ueditor  使用代码 end      ================
                // 试题类型
                questionType: "radio",
                // 试题分数
                questionScore: "1",
                // 是否必答
                is_must_answer: false,
                // 是否显示添加选项
                is_add_option: false,
                // 正确答案（单选A）
                radio_right_key: "A",
                // 正确答案（多选[A,B,C]）
                checkbox_right_key: ["A"],
                // 正确答案（对/错）
                judge_right_key: "对",
                // 单选/多选 选项列表
                optionList: [
                    {
                        index: "A",
                        content: ""
                    },
                    {
                        index: "B",
                        content: ""
                    }
                ],
                judgeOptionList: [
                    {
                        index: "对"
                    },
                    {
                        index: "错"
                    }
                ],
                // 主键id
                exam_id: "",
                // 解析
                resolution: {
                    defaultMsg: "", //默认显示的文字
                    ue1: "", // 不同编辑器必须不同的id
                    ueState: false //ue状态
                }
            };
            obj.questionType = sign;
            obj.ue1 = randomString(10);
            obj.resolution.ue1 = randomString(10);
            // 将试题对象添加至试题列表中
            self.questionList.push(obj);
            // Message.success("添加成功！");
            // 滚动条移动到最下
            sleep(500).then(() => {
                // 这里写sleep之后需要去做的事情
                var div = document.getElementById(
                    "scrolldIV"
                );
                div.scrollTop = div.scrollHeight;
            });
            this.$Tips({
                // 消息提示内容
                message: '添加成功',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })
        },
        delOption (index, key) {
            var self = this;
            let num = self.questionList[index].optionList.length;
            if (num <= 2) {
                // Message.warning("只有两个选项，不能删除");
                this.$Tips({
                    // 消息提示内容
                    message: "只有两个选项，不能删除",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
                return;
            }
            self.questionList[index].optionList.splice(key, 1);
            for (
                var i = 0;
                i < self.questionList[index].optionList.length;
                i++
            ) {
                let letter = returnLetterByIndex(i);
                self.questionList[index].optionList[i].index = letter;
            }
            // Message.success("删除成功！");
            this.$Tips({
                // 消息提示内容
                message: '删除成功',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })
        },
        deleteQuestion (index) {
            const self = this;
            // 当只剩下一个选项的时候不能删除
            if (self.questionList.length <= 1) {
                // Message.warning("只有一个试题不能删除！");
                this.$Tips({
                    // 消息提示内容
                    message: "只有一个试题不能删除",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
                return;
            }

            let exam_id = self.questionList[index].exam_id;
            if (exam_id) {
                let data = {
                    exam_id: exam_id
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.delQuestion(data)
                    .then((result) => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    })
                    .catch(() => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
            self.questionList.splice(index, 1); //在下标1处开始删除,删除一位
            // Message.success("删除成功！");
            this.$Tips({
                // 消息提示内容
                message: "删除成功",
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })
        },
        /**
         * @name: 返回试卷列表页
         * @author: camellia
         * @date: 2021-01-28
         */
        // goBack() {
        //     this.$router.back();
        // },
        ready (editorInstance) {
            var self = this;
            editorInstance.focus();
            var str = "exam_paper_" + self.bank_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function (editor) {
                return {
                    articleid: str //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        saveQuestionBank () {
            var self = this;
            for (var i = 0; i < self.questionList.length; i++) {
                if (
                    self.questionList[i].questionType == "radio" &&
                    self.questionList[i].radio_right_key == ""
                ) {
                    // Message.warning("单选需要选择正确选项！");
                    this.$Tips({
                        // 消息提示内容
                        message: "单选需要选择正确选项",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                } else if (
                    self.questionList[i].questionType == "checkbox" &&
                    self.questionList[i].checkbox_right_key.length < 1
                ) {
                    // Message.warning("多选至少需要选择一个正确选项！");
                    this.$Tips({
                        // 消息提示内容
                        message: "多选至少需要选择一个正确选项",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                if (self.questionList[i].questionScore == "") {
                    //    Message.info('请输入分数');
                    this.$Tips({
                        // 消息提示内容
                        message: "请输入分数",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                if (self.questionList[i].defaultMsg == "") {
                    // Message.info('请输入题目');
                    this.$Tips({
                        // 消息提示内容
                        message: "请输入题目",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                for (
                    var j = 0;
                    j < self.questionList[i].optionList.length;
                    j++
                ) {
                    if (
                        self.questionList[i].optionList[j].content == "" &&
                        self.questionList[i].questionType != "judge"
                    ) {
                        // Message.warning("请输入选项内容！");
                        this.$Tips({
                            // 消息提示内容
                            message: "请输入选项内容",
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            button: [
                                {
                                    type: 1,
                                    text: '知道了'
                                }
                            ]
                        })
                        return;
                    }
                }
            }
            let data = {
                questionList: self.questionList,
                bank_id: self.bank_id,
            }
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            var searchInfo = self.$route.query.searchInfo ? self.$route.query.searchInfo : " ";
            // 请求数据
            API.updateQuestionBank(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.$router.push("/teacher/questionList?bank_id=" + self.$route.query.bank_id + '&searchInfo=' + searchInfo + '&name=' + self.$route.query.name);
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(() => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
        },
        getData () {
            const self = this;

            const type = self.$route.query.sign;
            if (type == 'add') {
                // 将试题对象添加至试题列表中
                self.addquestion("radio");
            }
            else if (type == 'import') {
                return;
            }
            else {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                let data = {
                    bank_id: self.bank_id,
                    questionSelection: self.$route.query.questionSelection,
                };
                // 请求数据
                API.getQuestionBank(data)
                    .then((result) => {
                        if (result.questionList == "") {
                            // 将试题对象添加至试题列表中
                            self.addquestion("radio");
                        }
                        else {
                            self.questionList = result.questionList
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(() => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
        },
    }
};
