<template>
    <!-- 编辑题库页 -->
    <div>
        <div class="questionBankUpdate">
            <!-- 保存/立即开始按钮 -->
            <!-- <div class="save-button">
                <div class="right">
                    <el-button type="primary" @click="saveQuestionBank()"
                        >保存</el-button
                    >
                    <el-button type="success">立即开始</el-button>
                    <el-button @click="goBack()">返回</el-button>
                </div>
            </div> -->

            <div class="edit-content">
                <div class="add-button-div" v-if="addshow">
                    <!-- 添加题按钮 -->
                    <div class="add-question-div" >
                        <span class="themeFont add-question-title ">添加题目</span>
                        <el-button class="themecancelButton" @click="addquestion('radio')">单选题</el-button>
                        <el-button class="themecancelButton" @click="addquestion('checkbox')">多选题</el-button>
                        <el-button class="themecancelButton" @click="addquestion('judge')">判断题</el-button>
                        <!-- <el-button plain @click="addquestion('comple')">填空题</el-button> -->
                    </div>
                </div>

                <div class="edit-view" id="scrolldIV">
                    <!-- 试题内容 -->
                    <div
                        class="question-content"
                        v-for="(item, index) in questionList"
                        :key="index"
                        :id="index"
                        name="item"
                    >       
                        <div class="qusetion" >
                        <!-- 试题header -->
                        <div class="question-header-div">
                            <!-- 序号 -->
                            <span class="themeFont margin-left1">序号：{{ index + 1 }}</span>
                            <!-- 类型选项 -->
                            <span class="margin-left4">
                                <el-select v-model="item.questionType" placeholder="请选择">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </span>
                            <span class="themeFont margin-left4">
                                答对得分：
                            </span>
                            <span>
                                <el-input
                                    v-model="item.questionScore"
                                    placeholder="1"
                                    type="number"
                                ></el-input>
                            </span>

                            <span class="right cursor delete-icon"
                                ><i
                                    class="el-icon-delete"
                                    @click="deleteQuestion(index)"
                                ></i
                            ></span>
                        </div>
                        <!-- 富文本编辑器编辑题干 -->
                        <div>
                            <!-- 当单击该div时，展示编辑器 -->
                            <div class="edit_qusetionDiv" v-if="item.editQuestionShow"  @click="handleEditQuestion(index)" v-html="item.defaultMsg"></div>
                            <vue-ueditor-wrap
                                v-else
                                v-model="item.defaultMsg"
                                :config="myConfig"
                                @ready="ready"
                            ></vue-ueditor-wrap>
                        </div>
                        <!-- 选项 -->
                        <div>
                            <!-- 判断选项 -->
                            <div v-if="item.questionType == 'judge'">
                                <span
                                    v-for="(value, key) in item.judgeOptionList"
                                    :key="key"
                                    class="margin-left1"
                                >
                                    <el-radio
                                        v-model="item.judge_right_key"
                                        :label="value.index"
                                        border
                                        >{{ value.index }}</el-radio
                                    >
                                </span>
                            </div>
                            <!-- 单选选项 -->
                            <div v-if="item.questionType == 'radio'">
                                <div v-for="(value, key) in item.optionList" :key="key">
                                    <span>
                                        <el-radio
                                            v-model="item.radio_right_key"
                                            :label="value.index"
                                            border
                                            >{{ value.index }}</el-radio
                                        >
                                    </span>
                                    <span class="margin-left16 width80">
                                        <el-input
                                            v-model="value.content"
                                            class="width80"
                                            placeholder="请输入内容"
                                        ></el-input>
                                        <!-- {{value.content}} -->
                                    </span>
                                    <span
                                        class="right cursor delete-icon"
                                        @click="delOption(index, key)"
                                        ><i class="el-icon-close"></i
                                    ></span>
                                </div>
                            </div>
                            <!-- 多选选项 -->
                            <div v-if="item.questionType == 'checkbox'">
                                <el-checkbox-group v-model="item.checkbox_right_key">
                                    <div v-for="(value, key) in item.optionList" :key="key">
                                        <span>
                                            <el-checkbox
                                                :label="value.index"
                                                border
                                            ></el-checkbox>
                                        </span>
                                        <span class="margin-left16 width80">
                                            <el-input
                                                v-model="value.content"
                                                class="width80"
                                                placeholder="请输入内容"
                                            ></el-input>
                                            <!-- {{value.content}} -->
                                        </span>
                                        <span
                                            class="right cursor delete-icon"
                                            @click="delOption(index, key)"
                                            ><i class="el-icon-close"></i
                                        ></span>
                                    </div>
                                </el-checkbox-group>
                            </div>
                            <!-- 填空 -->
                            <div v-if="item.questionType == 'comple'">
                                <div v-for="(value, key) in item.optionList" :key="key">
                                    <span> 第{{ key + 1 }}空 </span>
                                    <span class="margin-left16 width80">
                                        <el-input
                                            v-model="value.content"
                                            class="width80"
                                            placeholder="请输入内容"
                                        ></el-input>
                                        <!-- {{value.content}} -->
                                    </span>
                                    <span
                                        class="right cursor delete-icon"
                                        @click="delOption(index, key)"
                                        ><i class="el-icon-close"></i
                                    ></span>
                                </div>
                            </div>
                        </div>
                        <!-- 添加选项 -->
                        <div v-if="item.questionType != 'judge'">
                            <span class="add-option cursor" @click="addOption(index)"
                                >＋添加选项</span
                            >
                            <!-- <el-button class="themeButton" icon="plus" @click="addOption(index)">添加选项</el-button> -->
                        </div>
                        <br />
                        <!-- 试题解析 -->
                        <div>
                            <div class="themeFont">试题解析(选填)：</div>
                            <el-input
                                class="width80"
                                placeholder="请输入解析内容"
                                @focus="updateUeditorSign(index)"
                                v-if="!item.resolution.ueState"
                            ></el-input>
                            <vue-ueditor-wrap
                                v-model="item.resolution.defaultMsg"
                                :config="myConfig"
                                @ready="ready"
                                v-else
                            ></vue-ueditor-wrap>
                        </div>
                        </div>

                        <!-- 必答题 + 多选最多几个 -->
                        <div>
                            <!-- 必答题 -->
                            <!-- <span style="height:40px;line-height:40px;">
                                必答题：<el-switch v-model="item.is_must_answer" style="margin-top:6px;"></el-switch>
                            </span> -->
                            <!-- 多选最多几个 -->
                            <!-- <span v-if="item.questionType == 'checkbox'"></span> -->
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="questionIndex">
                <div class="questionTitle">题目</div>
                <!-- <div class="scrollquestionIndex">
                    <div class="indexButton"  v-for="(item,index) in questionList" :key="index"><a :href="'#'+index">{{index+1}}</a></div>  
                </div> -->
                <el-button
                    class="scrollindexButton"
                    v-for="(item, index) in questionList"
                    :key="item.id"
                    @click="scroll(index)"
                    >{{ index + 1 }}
                </el-button>
            </div>
        </div>
        <div class="saveFix">
            <div class="questionBankUpdate">
                <div class="right">
                    <el-button class="themeButton" @click="saveQuestionBank()">保存</el-button>
                </div>
            </div>
        </div>
        <div class="backFix">
            <el-button class="themecancelButton" @click="goBack()">返回</el-button>
            <!-- <el-button type="primary" @click="execUpdateArticle()"
                >提&nbsp;&nbsp;交</el-button
            > -->
        </div>
    </div>

</template>
<style lang="scss" scoped>
// 引入编辑试题列表样式
    @import "@/assets/css/teacher/questionbank/questionBankUpdate.scss";
</style>
<style>
.el-input__inner{
    height: 2.0833vw;
    line-height: 2.0833vw;
    font: normal normal normal  0.8333vw Source Han Sans CN !important;
}
.el-radio.is-bordered{
    height: 2.0833vw;
    width: 3vw;
    line-height: 2.0833vw;
    padding: 0;
    padding-left:.5vw;
    /* box-sizing: border-box; */
    border: 1px solid #cccccc;
}
.el-radio__inner{
    width: 0.7292vw;
    height: 0.7292vw;
}
.el-radio__label{
    font: normal normal normal  0.8333vw Source Han Sans CN !important;
    padding-left: 0.5208vw;
}
.el-radio.is-bordered.is-checked{
    border: 1px solid #0d5570;
}
.questionBankUpdate .el-radio__input.is-checked .el-radio__inner{
    border-color: #0d5570;
    background: #0d5570;
}
.questionBankUpdate .el-radio__input.is-checked+.el-radio__label{
    color: #0D5570;
}
.edui-default .edui-editor-breadcrumb{
    display: none !important;
}
</style>
<script>
// 引入编辑试题列表js
    import questionBankUpdate from "@/assets/js/teacher/questionbank/questionBankUpdate.js";
    export default {
    ...questionBankUpdate
};
</script>
