import { request } from "@/request/http";

export default {
  delQuestion: (data) => {
    return request("POST", `/index.php/backquestionbank/delQuestion`, data);
  },
  updateQuestionBank: (data) => {
    return request("POST", `/index.php/backquestionbank/updateQuestionBank`, data);
  },
  getQuestionBank: (data) => {
    return request("GET", `/index.php/backquestionbank/getQuestionBank`, data);
  },
};